import './download-teaser.scss';
import { Slider } from '../slider/slider';

class DownloadTeaser {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-download-teaser'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$downloadTeaser = element;
        this.slider = null;
    }

    initialize () {
        this.initSlider(this.$downloadTeaser);
    }

    initSlider (slider) {
        this.slider = new Slider(slider, {
            initAttr: this.settings.initAttr,
            autoHeight: true,
            slidesPerView: 3,
            slidesPerGroup: 3,
            autoplay: false,
            breakpoints: {
                767: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                1023: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                1279: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                }
            },
            calculateHeight: false,
            prevnext: false,
            speed: 750
        });

        const waitForSlider = setInterval(() => {
            if (this.slider !== null) {
                clearInterval(waitForSlider);
                setTimeout(() => {
                    this.slider.slider.update();
                }, 2000);
            }
        }, 500);
    }
}

export { DownloadTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$DownloadTeaser = $context.querySelectorAll('[data-download-teaser="root"]');
        for (let i = 0; i < $$DownloadTeaser.length; i++) {
            const $DownloadTeaser = new DownloadTeaser($$DownloadTeaser[i]);
            $DownloadTeaser.initialize();
        }
    }
});
